import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { getCodeList } from '@/api/user';
export default defineComponent({
  name: 'Code',
  components: {},

  setup() {
    const state = reactive({
      list: []
    });
    const route = useRoute();
    const router = useRouter();

    const onEdit = data => {
      router.push({
        name: 'Car',
        query: data
      });
    }; // const fetchData = async () => {
    //   const toast = Toast.loading({
    //     message: '加载中...',
    //     forbidClick: true,
    //   })
    //   const { data } = await getCodeList()
    //   toast.clear()
    //   state.list = data
    // }


    onMounted(async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        data
      } = await getCodeList();
      toast.clear();
      state.list = data; // fetchData()

      setTimeout(() => {
        let params = route.params;

        if (params.code_sn) {
          router.push({
            name: 'Car',
            query: {
              code_sn: params.code_sn
            }
          });
        }
      }, 500);
    });
    return { ...toRefs(state),
      onEdit
    };
  }

});