import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell = _resolveComponent("van-cell");

  const _component_van_cell_group = _resolveComponent("van-cell-group");

  const _component_van_empty = _resolveComponent("van-empty");

  return _ctx.list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
    return _openBlock(), _createBlock(_component_van_cell_group, {
      inset: "",
      style: {
        "margin-top": "20px",
        "margin-bottom": "20px"
      },
      onClick: $event => _ctx.onEdit(item),
      key: index
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell, {
        title: '挪车码：' + item.code_sn,
        value: "编辑"
      }, null, 8, ["title"]), item.is_bind == 1 ? (_openBlock(), _createBlock(_component_van_cell, {
        key: 0,
        center: "",
        title: item.license_plate_number,
        value: "已启用",
        label: item.phone_number
      }, null, 8, ["title", "label"])) : (_openBlock(), _createBlock(_component_van_cell, {
        key: 1,
        center: "",
        title: "未绑定",
        value: "已解绑",
        label: "未绑定"
      }))]),
      _: 2
    }, 1032, ["onClick"]);
  }), 128))])) : (_openBlock(), _createBlock(_component_van_empty, {
    key: 1,
    description: "暂无挪车码",
    style: {
      "margin-top": "200px"
    }
  }));
}